var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(!_vm.isPeople)?_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":"选择人员","append-to-body":true,"visible":_vm.personVisible,"width":"630px","before-close":function () {
        _vm.beforeClose();
      },"close-on-click-modal":false},on:{"update:visible":function($event){_vm.personVisible=$event}}},[_c('div',{staticClass:"common-chooseItem-container"},_vm._l((_vm.checkedList),function(item,index){return _c('div',{key:index,staticClass:"common-choose-item"},[_c('span',[_vm._v(_vm._s(item.name))]),(
            _vm.type == 'one' &&
              (item.name == '公司管理员' || item.name == '超级管理员'))?_c('i'):_c('i',{staticClass:"el-icon-circle-close",on:{"click":function () {
              _vm.deletePerson(item);
            }}})])}),0),_c('br'),_c('br'),_c('div',{staticClass:"common-person-container"},[_c('div',{staticClass:"person-title"},[_c('div',{staticClass:"left-text"},[_vm._v("组织成员")])]),_c('div',{staticClass:"person-content"},[_c('div',{staticClass:"content-left"},[_vm._v(" 角色 ")]),_c('div',{staticClass:"content-right"},[_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("全选")]),_vm._l((_vm.roleData),function(item,index){return _c('div',{key:index,staticClass:"checkone"},[_c('el-checkbox',{attrs:{"disabled":_vm.type=='one'&&(item.name == '公司管理员'||item.name == '超级管理员')},on:{"change":function (val) {
                  _vm.changePerson(val, item);
                }},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}},[_vm._v(_vm._s(item.name))])],1)})],2)])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":function () {
            _vm.beforeClose();
          }}},[_vm._v("取 消")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {
            _vm.saveRole();
          }}},[_vm._v("确 定")])],1)]):_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":"选择人员","append-to-body":true,"visible":_vm.personVisible,"width":"630px","before-close":function () {
        _vm.beforeClose();
      },"close-on-click-modal":false},on:{"update:visible":function($event){_vm.personVisible=$event}}},[_c('div',{staticClass:"common-chooseItem-container"},_vm._l((_vm.checkedList),function(item,index){return _c('div',{key:index,staticClass:"common-choose-item"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"el-icon-circle-close",on:{"click":function () {
              _vm.deletePerson(item);
            }}})])}),0),_c('br'),_c('br'),_c('div',{staticClass:"common-person-container"},[_c('div',{staticClass:"person-title"},[_c('div',{staticClass:"left-text"},[_vm._v("组织成员")]),_c('div',{staticClass:"right-seach"},[_c('el-input',{staticClass:"seach-input",attrs:{"placeholder":"请输入内容","prefix-icon":"el-icon-search"},on:{"change":function (val) { return _vm.seachFun(val); }},model:{value:(_vm.seachInfo),callback:function ($$v) {_vm.seachInfo=$$v},expression:"seachInfo"}})],1)]),_c('div',{staticClass:"person-content"},[_c('div',{staticClass:"content-left"},[_c('el-tree',{attrs:{"data":_vm.roleData,"props":_vm.defaultProps},on:{"node-click":function (val) {
                _vm.departTreeFun(val);
              }}})],1),_c('div',{staticClass:"content-right"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((_vm.personData),function(item,index){return _c('el-radio',{key:index,staticClass:"peopleradio",attrs:{"label":item.id},on:{"change":function (val) {
                  _vm.changeRadioPerson(val);
                }},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(" "+_vm._s(item.nickName)+" ")])}),1)])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"common-screen-btn",on:{"click":function () {
            _vm.beforeClose();
          }}},[_vm._v("取 消")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {
            _vm.saveRole();
          }}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }